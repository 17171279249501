@import '@ng-select/ng-select/themes/material.theme.css';
@import '/src/dinamyc';

.bg-red{
  background-color: #f31136;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

html, body { height: 100%; }

.font-size-tittle{
  font-size: 1.37em !important;
}

/* Spinner */
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: black;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

.container-load{
  height: 100vh;
}

.error {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #f86c6b
}

.pointer {cursor: pointer;}
.help {cursor: help;}

.mh-50{
  min-height: 50vh !important;
}

.new-line {
  white-space:pre-wrap;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}

.fa-1-5x {
  font-size: 1.5em;
}


::ng-deep .mat-form-field-underline {
  background-color: lightgrey !important;
}

.index-10 {
  z-index: 10;
}